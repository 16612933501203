import { Typography } from '@ffn/sunbeam'
import { Button } from 'components/Button'
import Section from 'components/Section'
import { Grid } from '@ffn/sunbeam'
import styles from './NotFound.module.scss'

function NotFound() {
  return (
    <Section>
      <Grid className={styles['container-grid']}>
        <Typography
          className={styles['text']}
          style={{ textAlign: 'center' }}
          data-testid="500-error-title-text"
          component="h1"
          variant="displayL10"
        >
          404 - Page Not Found
        </Typography>
        <Typography
          className={styles['text']}
          style={{ textAlign: 'center' }}
          data-testid="500-error-subtitle-text"
          component="p"
          variant="displayM10"
        >
          An unexpected error occurred
        </Typography>
        <Grid className={styles['text']}>
          <Button
            className={styles['button']}
            data-testid="500-error-return-home-button"
            sx={{ mt: 4 }}
            href="/"
          >
            Take me back to Achieve.com
          </Button>
        </Grid>
      </Grid>
    </Section>
  )
}

export { NotFound }
